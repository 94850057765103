import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CountryDialog from "./CountryDialog";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";

export default function CountryCard({ completeCountry }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Get the first language and determine the length
  const firstLanguage = completeCountry.countryData?.languages
    ? Object.values(completeCountry.countryData.languages)[0]
    : "N/A";
  const isLongLanguage = firstLanguage.length > 7;

  return (
    <div>
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          sx={{ height: 140 }}
          image={completeCountry.countryData?.flags?.svg || "/logo512.png"} // Use the flag from country data.
          title={completeCountry.countryData?.name?.common || "Country Name"}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {completeCountry.countryData?.name?.common || "Country Name"}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <Box>
              <Typography variant="body1" paragraph>
                <Box display="flex" alignItems="center" flexWrap={isLongLanguage ? "wrap" : "nowrap"}>
                  <span
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "8px",
                    }}
                  >
                    🗣️
                  </span>
                  {firstLanguage}
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ margin: "0 16px" }}
                  />
                  <span
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "8px",
                    }}
                  >
                    💵
                  </span>
                  {completeCountry.costOfLivingData?.summary?.[
                    "Single person estimated monthly costs"
                  ] || "N/A"}
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ margin: "0 16px" }}
                  />
                  <span
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "8px",
                    }}
                  >
                    ❌
                  </span>
                  {completeCountry.safetyData?.advisory.score || "N/A"}
                </Box>
              </Typography>
            </Box>
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={handleClickOpen}>
            Learn More
          </Button>
        </CardActions>
      </Card>

      {/* Use the CountryDialog component */}
      <CountryDialog
        open={open}
        handleClose={handleClose}
        completeCountry={completeCountry}
      />
    </div>
  );
}
