import React, { useState } from "react";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const ContactUs = () => {
  const [formData, setFormData] = useState({ name: "", email: "", subject: "", message: "" });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Set the form data to be sent to Netlify.
    const myForm = event.target;
    const formDataToSend = new FormData(myForm);

    // Send the form data to Netlify.
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formDataToSend).toString(),
    })
      .then(() => {
        setSubmitted(true);
        setFormData({ name: "", email: "", subject: "", message: "" }); // Reset the form.
        setError(false); // Reset error state.
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        setError(true); // Set error state.
      });
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        padding: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ marginBottom: 2, textAlign: "center" }}
      >
        Get in Touch with Us
      </Typography>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          padding: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ marginBottom: 2 }}>
          We&apos;d love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to drop us a message using the form below or send us an email at: <Link>support@mypassportbros.com</Link>.
        </Typography>
        <form
          data-netlify="true"
          name="contact" // This should match the value of the hidden input below.
          method="post"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" /> {/* Hidden input for Netlify */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                fullWidth
                value={formData.name}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                fullWidth
                value={formData.email}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Subject"
                name="subject"
                variant="outlined"
                fullWidth
                value={formData.subject}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Message"
                name="message"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={formData.message}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  "&:hover": { backgroundColor: "primary.dark" },
                }}
              >
                Send Message
              </Button>
            </Grid>
          </Grid>
        </form>
        {submitted && <Typography color="green">Your message has been sent successfully!</Typography>}
        {error && <Typography color="red">There was an error sending your message. Please try again.</Typography>}
      </Paper>
    </Container>
  );
};

export default ContactUs;
