import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SuccessPage = () => {

  useEffect(() => {
    // Simulate setting a paid status after a successful payment.
    localStorage.setItem(process.env.REACT_APP_SUCCESS_NAME, 'true');
  }, []);

  return (
    <Container maxWidth="sm" sx={{ mt: 8, textAlign: 'center' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ textAlign: "center" }}
      >
        <CheckCircleOutlineIcon color="primary" sx={{ fontSize: 80, mb: 2 }} />
        <Typography variant="h4" gutterBottom>
          Thank You For Your Subscription!
        </Typography>
        <Typography variant="body1" paragraph>
          You now have access to all the membership content. Use the navigation bar above to explore our exclusive content.
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, mb: 4 }}>
          Your membership status has been updated successfully.
        </Typography>
      </Box>
    </Container>
  );
};

export default SuccessPage;
