import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/**
 * Renders the About Us page.
 *
 * @returns {JSX.Element} The About Us page component.
 */
function AboutUsPage() {
  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Box sx={{ mb: 5, textAlign: "center" }}>
        <Typography variant="h3" component="h1" gutterBottom>
          About Us
        </Typography>
        <Typography variant="h6" color="text.secondary">
          At MyPassPortBros, we're not just another travel and dating resource.
          We’re the <b>ONLY</b> platform tailored specifically for the{" "}
          <b>passport bros</b>. If you&apos;ve ever wondered what life could be
          like beyond the borders of your own country, you&apos;re in the right
          place. We provide <b>exclusive tools</b> and an insider community
          that&apos;ll help you confidently explore new cultures and
          relationships abroad.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h2" gutterBottom>
            Who We Are
          </Typography>
          <Typography variant="body1" color="text.secondary">
            We're the <b>first and only platform</b> to recognize the unique
            needs of the <b>Passport Bros movement.</b> The world is a vast
            place, and navigating its dating and cultural landscapes can be
            tricky. At MyPassPortBros, we&apos;ve done the hard work for you. We
            offer <b>everything you need</b> to date and live internationally
            with ease and confidence. Whether you&apos;re a newbie or a seasoned
            traveler, we&apos;ve simplified it for you.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" component="h2" gutterBottom>
            What We Offer
          </Typography>
          <Grid container spacing={3}>
            {/* Offer 1 */}
            <Grid item xs={12} sm={6}>
              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom>
                    1.
                    <span
                      style={{
                        fontSize: "1.5rem",
                        marginRight: "8px",
                        marginLeft: "8px",
                      }}
                    >
                      📋
                    </span>
                    MyPassPortBros List
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Your <b>ultimate tool</b> for dating abroad! Gain access to{" "}
                    <b>never-before-seen insights</b> on dating, cost of living,
                    and safety across countries, all consolidated in one place.
                    No need to dig through random sources — we&apos;ve curated
                    it all to make your decisions <b>easy.</b>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Offer 2 */}
            <Grid item xs={12} sm={6}>
              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom>
                    2.
                    <span
                      style={{
                        fontSize: "1.5rem",
                        marginRight: "8px",
                        marginLeft: "8px",
                      }}
                    >
                      💬
                    </span>
                    Private Discord Community
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Connect instantly with other Passport Brothers. Imagine a
                    community of <b>like-minded individuals</b>, just a message
                    away. Get real-time advice, support, and the chance to make
                    lasting connections. And guess what? It&apos;s <b>safe</b>{" "}
                    and completely private — no trolls, no noise, just valuable
                    conversations.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Offer 3 */}
            <Grid item xs={12} sm={6}>
              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" component="h3" gutterBottom>
                    3.
                    <span
                      style={{
                        fontSize: "1.5rem",
                        marginRight: "8px",
                        marginLeft: "8px",
                      }}
                    >
                      🚧
                    </span>
                    Platform in Progress
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    This is just the beginning. MyPassPortBros is constantly
                    growing to include <b>new features</b> like forums, advanced
                    tools, and more. Stay tuned, because we&apos;re about to
                    make things better than ever!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" component="h2" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Our mission is simple: <b>empower Pass Port Bros</b> to explore the
            world, discover new relationships, and grow personally along the
            way. We're building an awesome community where self-improvement is
            at the core, and we're making it <b>easy and fun</b> for you to take
            that first step. Every update, every feature, every tool — all
            designed with you in mind.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" component="h2" gutterBottom>
            Join Us on This Journey
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            If you're ready to start an adventure that&apos;ll change your life,
            <b>MyPassPortBros</b> is where it begins. No more feeling stuck. No
            more wondering if you&apos;re making the right moves. Become a part
            of the Passport Bro's today and start your journey to new countries
            and relationships. Welcome to your future!
          </Typography>
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large" // Make the button larger
              sx={{ minWidth: "fit-content" }}
              href="https://buy.stripe.com/bIY8xk2y30JPaOI8wy"
              endIcon={<ArrowForwardIcon style={{ color: "white" }} />}
            >
              Become a Passport Bro Today!
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AboutUsPage;
