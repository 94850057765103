import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to <strong>MyPassPortBros</strong>. Your privacy is important
          to us. This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you visit our website or use our
          services. Please read this policy carefully.
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* Section 1: Information We Collect */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" paragraph>
            We are committed to collecting only the minimum amount of personal
            data necessary to provide our services. The types of personal data
            we may collect include:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Contact Information:</strong> Name, email address, and phone
            number.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Account Information:</strong> Username, password, and
            billing details (if applicable).
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Usage Data:</strong> Information about how you use our
            website and services, including IP address and browser type.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 2: How We Use Your Information */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We use your information to:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Provide and Maintain Our Services:</strong> To deliver the
            services you request and ensure their functionality.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Communicate with You:</strong> To respond to your inquiries,
            send you updates about our services, and manage your account.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Improve Our Services:</strong> To analyze usage data and
            enhance our services based on user feedback and behavior.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 3: Data Retention */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            3. Data Retention
          </Typography>
          <Typography variant="body1" paragraph>
            We retain your personal data only as long as necessary to fulfill
            the purposes outlined in this Privacy Policy or as required by law.
            Once it is no longer needed, we will securely delete or anonymize
            your data.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 4: Data Protection and Security */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            4. Data Protection and Security
          </Typography>
          <Typography variant="body1" paragraph>
            We implement appropriate technical and organizational measures to
            protect your personal data from unauthorized access, disclosure,
            alteration, or destruction. This includes using secure servers,
            encryption, and regular security audits.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 5: Sharing Your Information */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            5. Sharing Your Information
          </Typography>
          <Typography variant="body1" paragraph>
            We do not sell, trade, or otherwise transfer your personal data to
            outside parties. However, we may share your information with:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Service Providers:</strong> Third-party vendors who assist
            us in operating our website and providing our services (e.g.,
            payment processors). These third parties are contractually obligated
            to protect your data and use it only for the purposes specified.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Legal Requirements:</strong> If required by law, to comply
            with legal obligations or to protect our rights and safety.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 6: Your Rights */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            6. Your Rights
          </Typography>
          <Typography variant="body1" paragraph>
            Under GDPR, you have the following rights concerning your personal
            data:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Access:</strong> You can request a copy of the personal data
            we hold about you.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Rectification:</strong> You can request that we correct or
            update any inaccurate or incomplete information.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Erasure:</strong> You can request that we delete your
            personal data, subject to certain conditions.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Restriction:</strong> You can request that we restrict the
            processing of your personal data in certain situations.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Objection:</strong> You can object to the processing of your
            personal data for specific purposes.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Data Portability:</strong> You can request to receive your
            data in a structured, commonly used format.
          </Typography>
          <Typography variant="body1" paragraph>
            To exercise these rights, please contact us using the details
            provided below.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 7: Cookies and Tracking Technologies */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            7. Cookies and Tracking Technologies
          </Typography>
          <Typography variant="body1" paragraph>
            We use cookies and similar tracking technologies to enhance your
            browsing experience and analyze site traffic. Cookies are small
            files stored on your device that help us remember your preferences
            and improve our services. You can control cookies through your
            browser settings.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 8: Third-Party Links */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            8. Third-Party Links
          </Typography>
          <Typography variant="body1" paragraph>
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or content of such external
            sites. We encourage you to review their privacy policies before
            providing any personal data.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 9: Changes to This Privacy Policy */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            9. Changes to This Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated effective date. Your
            continued use of our services after such changes indicates your
            acceptance of the updated policy.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 10: Contact Us */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            10. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Email:</strong> support@mypassportbros.com
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicyPage;
