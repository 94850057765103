import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "@mui/material";

/* eslint-disable react/prop-types */

export default function CountryDialog({ open, handleClose, completeCountry }) {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Function to capitalize the first letter of a string.
  const capitalizeFirstLetter = (str) => {
    if (typeof str === "string" && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    // Return the original value if it's not a string or is empty.
    return str;
  };

  // Split visa text into paragraphs.
  const splitIntoParagraphs = (text) => {
    // Split the text by the newline character \n to create paragraphs
    const paragraphs = text.split("\n");

    // Return the array of paragraphs with any extra whitespace removed
    return paragraphs.map((paragraph) => paragraph.trim()).filter(Boolean); // Remove empty paragraphs
  };
  // Add links to the visa text.
  const replaceLinksInText = (text, links) => {
    let updatedText = text;

    links.forEach((link) => {
      const { name, href } = link;
      const linkPattern = new RegExp(name, "gi"); // Create a case-insensitive regex pattern for the link name
      updatedText = updatedText.replace(
        linkPattern,
        `<a href="${href}" target="_blank" rel="noopener noreferrer">${name}</a>`
      );
    });

    return updatedText;
  };

  const countryCca3 = completeCountry?.countryData?.cca3.toLowerCase();
  const countryCca2 = completeCountry?.countryData?.cca2;

  // Combine area and area comparative into one.
  const areaValue =
    completeCountry.demographicData?.["Geography"]?.["Area"]?.["total "]
      ?.text || "N/A";
  const areaComparativeValue =
    completeCountry.demographicData?.["Geography"]?.["Area - comparative"]
      ?.text || "N/A";

  // Remove the p tag from the text string.
  const ethnicityText =
    completeCountry.demographicData?.["People and Society"]?.["Ethnic groups"]
      ?.text || "N/A";
  const cleanedEthnicityText = ethnicityText.replace(/<\/?p>/g, "");

  const combinedArea =
    areaValue !== "N/A" && areaComparativeValue !== "N/A"
      ? `${areaValue} (${areaComparativeValue})`
      : areaValue !== "N/A"
      ? areaValue
      : "N/A";

  // Clean and split the demographic profile text into paragraphs.
  const demographicProfile =
    completeCountry.demographicData?.["People and Society"]?.[
      "Demographic profile"
    ]?.text ||
    completeCountry.demographicData?.Introduction?.Background?.text ||
    "No demographic data available.";

  const splitIntoParagraphsDemographic = (text, maxLength = 400) => {
    const sentences = text.match(/[^.!?]+[.!?]+/g) || []; // Split text into sentences
    let paragraphs = [];
    let currentParagraph = "";

    sentences.forEach((sentence) => {
      if (currentParagraph.length + sentence.length > maxLength) {
        paragraphs.push(currentParagraph.trim()); // Push current paragraph to paragraphs array
        currentParagraph = sentence; // Start a new paragraph
      } else {
        currentParagraph += sentence; // Add sentence to current paragraph
      }
    });

    if (currentParagraph.length > 0) {
      paragraphs.push(currentParagraph.trim()); // Push any remaining text as a final paragraph
    }

    return paragraphs;
  };

  const cleanedDemographicProfile = demographicProfile
    .split(/<\/p>/) // Split the string at </p> tags.
    .map((paragraph) =>
      paragraph
        .replace(/<p>/g, "") // Remove remaining <p> tags
        .replace(/<br\s*\/?>/g, "") // Remove <br> tags (both <br> and <br/>)
        .trim()
    )
    .filter((paragraph) => paragraph.length > 0) // Filter out any empty paragraphs
    .flatMap((paragraph) => splitIntoParagraphsDemographic(paragraph)); // Split long paragraphs into smaller ones

  // The url for data that cant't be fetched free with API.
  const urlExpatistan = `https://www.expatistan.com/cost-of-living/country/${completeCountry.countryData?.name?.common}`;
  const urlSimiliarWeb = `https://www.similarweb.com/top-apps/google/${completeCountry.countryData?.name?.common}/dating/`;
  const urlTravelStateGov = `https://travel.state.gov/content/travel/en/international-travel/International-Travel-Country-Information-Pages/${completeCountry.countryData?.name?.common}.html`;

  // Combined and sorted data based on preferred order.
  const combinedAndSortedData = [
    {
      key: "Country Name",
      value: completeCountry.countryData?.name?.common || "N/A",
      icon: "🏳️", // Flag icon
    },
    {
      key: "Population",
      value: completeCountry.countryData?.population?.toLocaleString() || "N/A",
      icon: "👥", // People icon
    },
    {
      key: "Capital",
      value: completeCountry.countryData?.capital || "N/A",
      icon: "🏙️", // City icon
    },
    {
      key: "Language",
      value: completeCountry.countryData?.languages
        ? Object.values(completeCountry.countryData.languages).join(", ")
        : "N/A",
      icon: "🗣️", // Language icon
    },
    {
      key: "Currency",
      value: completeCountry.countryData?.currencies
        ? `${Object.values(completeCountry.countryData?.currencies)[0].name} (${
            Object.values(completeCountry.countryData?.currencies)[0].symbol
          })`
        : "N/A",
      icon: "💵", // Money icon
    },
    {
      key: "Religion",
      value:
        completeCountry.demographicData?.["People and Society"]?.["Religions"]
          ?.text || "N/A",
      icon: "⛪", // Church icon
    },
    {
      key: "Ethnicity",
      value: cleanedEthnicityText,
      icon: "👤", // Person icon
    },
    {
      key: "Currently Married Women",
      value:
        completeCountry.demographicData?.["People and Society"]?.[
          "Currently married women (ages 15-49)"
        ]?.text || "N/A",
      icon: "💍", // Diamond ring icon
    },
    {
      key: "Mother’s Mean Age at First Birth",
      value:
        completeCountry.demographicData?.["People and Society"]?.[
          "Mother's mean age at first birth"
        ]?.text || "N/A",
      icon: "👶", // Baby icon
    },
    {
      key: "Obesity Prevalence Rate",
      value:
        completeCountry.demographicData?.["People and Society"]?.[
          "Obesity - adult prevalence rate"
        ]?.text || "N/A",
      icon: "⚖️", // Scale icon
    },
    {
      key: "Total Fertility Rate",
      value:
        completeCountry.demographicData?.["People and Society"]?.[
          "Total fertility rate"
        ]?.text || "N/A",
      icon: "🤰", // Pregnant woman icon
    },
    {
      key: "Contraceptive Prevalence Rate Of Women Aged 16-49",
      value:
        completeCountry.demographicData?.["People and Society"]?.[
          "Contraceptive prevalence rate"
        ]?.text || "N/A",
      icon: "💊", // Pill icon
    },
    {
      key: "Tobacco Use (Female)",
      value:
        completeCountry.demographicData?.["People and Society"]?.["Tobacco use"]
          ?.female?.text || "N/A",
      icon: "🚬", // Smoking icon
    },
    {
      key: "Region",
      value: completeCountry.countryData?.region || "N/A",
      icon: "🌍", // Globe icon
    },
    {
      key: "Subregion",
      value: completeCountry.countryData?.subregion || "N/A",
      icon: "🌎", // Another globe icon
    },
    {
      key: "Major Urban Areas",
      value:
        completeCountry.demographicData?.["People and Society"]?.[
          "Major urban areas - population"
        ]?.text || "N/A",
      icon: "🏢", // Building icon
    },
    {
      key: "Geographic Coordinates",
      value:
        completeCountry.demographicData?.["Geography"]?.[
          "Geographic coordinates"
        ]?.text || "N/A",
      icon: "📍", // Location pin icon
    },
    {
      key: "Climate",
      value:
        completeCountry.demographicData?.["Geography"]?.["Climate"]?.text ||
        "N/A",
      icon: "🌡️", // Thermometer icon
    },
    {
      key: "Area",
      value: combinedArea,
      icon: "📏", // Ruler icon
    },
    {
      key: "Terrain",
      value:
        completeCountry.demographicData?.["Geography"]?.["Terrain"]?.text ||
        "N/A",
      icon: "🏞️", // Mountain landscape icon
    },
    {
      key: "Borders",
      value: completeCountry.countryData?.borders
        ? completeCountry.countryData?.borders.join(", ")
        : "N/A",
      icon: "🛂", // Passport control icon
    },
    {
      key: "Citizenship Requirement",
      value:
        completeCountry.demographicData?.["Government"]?.["Citizenship"]?.[
          "residency requirement for naturalization"
        ]?.text || "N/A",
      icon: "🆔", // ID card icon
    },
    {
      key: "Capital Time Difference",
      value:
        completeCountry.demographicData?.["Government"]?.["Capital"]?.[
          "time difference"
        ]?.text || "N/A",
      icon: "🕰️", // Clock icon
    },
    {
      key: "Google Maps",
      value: completeCountry.countryData?.maps?.googleMaps ? (
        <a
          href={completeCountry.countryData?.maps?.googleMaps}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Google Maps
        </a>
      ) : (
        "N/A"
      ),
      icon: "🗺️", // Map icon
    },
  ];

  // Emojis for cost of living.
  const emojiCostOfLivingList = {
    "Family of four estimated monthly costs": "👨‍👩‍👧‍👦",
    "Single person estimated monthly costs": "🧑",
    "Basic lunchtime menu (including a drink) in the business district": "🍽️",
    "Combo meal in fast food restaurant (big mac meal or similar)": "🍔",
    "500 gr (1 lb.) of boneless chicken breast": "🍗",
    "1 liter (1 qt.) of whole fat milk": "🥛",
    "12 eggs, large": "🥚",
    "1 kg (2 lb.) of tomatoes": "🍅",
    "500 gr (16 oz.) of local cheese": "🧀",
    "1 kg (2 lb.) of apples": "🍏",
    "1 kg (2 lb.) of potatoes": "🥔",
    "0.5 l (16 oz) domestic beer in the supermarket": "🍺",
    "1 bottle of red table wine, good quality": "🍷",
    "2 liters of coca-cola": "🥤",
    "Bread for 2 people for 1 day": "🍞",
    "Monthly rent for 85 m2 (900 sqft) furnished accommodation in expensive area":
      "🏙️",
    "Monthly rent for 85 m2 (900 sqft) furnished accommodation in normal area":
      "🏠",
    "Utilities 1 month (heating, electricity, gas ...) for 2 people in 85m2 flat":
      "💡",
    "Monthly rent for a 45 m2 (480 sqft) furnished studio in expensive area":
      "🏢",
    "Monthly rent for a 45 m2 (480 sqft) furnished studio in normal area": "🏘️",
    "Utilities 1 month (heating, electricity, gas ...) for 1 person in 45 m2 (480 sqft) studio":
      "⚡",
    "Internet 8 mbps (1 month)": "🌐",
    "40” flat screen tv": "📺",
    "Microwave 800/900 watt (bosch, panasonic, lg, sharp, or equivalent brands)":
      "🔄",
    "Laundry detergent (3 l. ~ 100 oz.)": "🧴",
    "Hourly rate for cleaning help": "🧹",
    "1 pair of jeans (levis 501 or similar)": "👖",
    "1 summer dress in a high street store (zara, h&m or similar retailers)":
      "👗",
    "1 pair of sport shoes (nike, adidas, or equivalent brands)": "👟",
    "1 pair of men’s leather business shoes": "👞",
    "Volkswagen golf 1.4 tsi 150 cv (or equivalent), with no extras, new": "🚗",
    "1 liter (1/4 gallon) of gas": "⛽",
    "Monthly ticket public transport": "🚌",
    "Cold medicine for 6 days (tylenol, frenadol, coldrex, or equivalent brands)":
      "💊",
    "1 box of antibiotics (12 doses)": "💉",
    "Short visit to private doctor (15 minutes)": "👨‍⚕️",
    "1 box of 32 tampons (tampax, ob, ...)": "🩹",
    "Deodorant, roll-on (50ml ~ 1.5 oz.)": "🧴",
    "Hair shampoo 2-in-1 (400 ml ~ 12 oz.)": "🧼",
    "4 rolls of toilet paper": "🧻",
    "Tube of toothpaste": "🪥",
    "Standard men's haircut in expat area of the city": "💇‍♂️",
    "Basic dinner out for two in neighborhood pub": "🍲",
    "2 tickets to the movies": "🎥",
    "2 tickets to the theater (best available seats)": "🎭",
    "Dinner for two at an italian restaurant in the expat area including appetisers, main course, wine and dessert":
      "🍝",
    "1 cocktail drink in downtown club": "🍹",
    "Cappuccino in expat area of the city": "☕",
    "1 beer in neighbourhood pub (500ml or 1pt.)": "🍻",
    "Ipad wi-fi 128gb": "📱",
    "1 min. of prepaid mobile tariff (no discounts or plans)": "📞",
    "1 month of gym membership in business district": "🏋️‍♂️",
    "1 package of marlboro cigarettes": "🚬",
  };

  // Emojis for visa.
  const emojiVisaList = {
    "blank passport pages": "📄",
    "currency restrictions for entry": "💰",
    "currency restrictions for exit": "💵",
    "passport validity": "🛂",
    "tourist visa required": "🎫",
    vaccinations: "💉",
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth="md"
      fullWidth
    >
      <div style={{ position: "relative", height: 200, flexShrink: 0 }}>
        <CardMedia
          sx={{
            height: "100%",
            backgroundSize: "cover",
          }}
          image={completeCountry.countryData?.flags?.svg || "/logo512.png"} // Use the flag from country data.
          title={completeCountry.countryData?.name?.common || "Country Name"}
          alt={completeCountry.countryData?.flags?.alt || "Country Flag"}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{
              color: "#fff",
              textAlign: "center",
              padding: "8px 16px",
              borderRadius: "4px",
            }}
          >
            {completeCountry.countryData?.name?.common || "Country Name"}
          </Typography>
        </div>
      </div>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable tabs"
      >
        <Tab label="Overview" />
        <Tab label="Description" />
        <Tab label="Cost Of Living" />
        <Tab label="Safety" />
        <Tab label="Dating" />
        <Tab label="Visa" />
      </Tabs>
      <DialogContent
        sx={{
          bgcolor: "background.default",
        }}
      >
        {selectedTab === 0 && (
          <Box>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {combinedAndSortedData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <span
                          style={{
                            fontSize: "1.5rem",
                            marginRight: "8px",
                          }}
                        >
                          {item.icon || "❓"}
                        </span>
                        <strong>{item.key}</strong>
                      </TableCell>
                      <TableCell>{capitalizeFirstLetter(item.value)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {selectedTab === 1 && (
          <Box
            sx={{
              padding: 2,
            }}
          >
            {cleanedDemographicProfile.map((paragraph, index) => (
              <Typography key={index} variant="body1" paragraph>
                {paragraph}
              </Typography>
            ))}
          </Box>
        )}
        {selectedTab === 2 && completeCountry.costOfLivingData && (
          <Box p={2}>
            {Object.entries(completeCountry.costOfLivingData)
              .filter(([category]) => category !== "countryName")
              .map(([category, items]) => {
                // Format the category name
                const formattedCategory =
                  category === "personalCare"
                    ? "Personal Care"
                    : category.charAt(0).toUpperCase() + category.slice(1);

                return (
                  <Box key={category} mb={4}>
                    <TableContainer component={Paper}>
                      <Table>
                        {/* Table Header */}
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              <Typography variant="h6" gutterBottom>
                                {formattedCategory}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        <TableBody>
                          {Object.entries(items).map(([item, value]) => (
                            <TableRow key={item}>
                              <TableCell>
                                <span
                                  style={{
                                    fontSize: "1.5rem",
                                    marginRight: "8px",
                                  }}
                                >
                                  {emojiCostOfLivingList[item] || "❓"}
                                </span>
                                <strong>{item}</strong>
                              </TableCell>
                              <TableCell>
                                {capitalizeFirstLetter(value)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                );
              })}
          </Box>
        )}

        {selectedTab === 3 && (
          <Box>
            <Typography variant="body1" paragraph>
              <Box display="flex" alignItems="center">
                <span
                  style={{
                    fontSize: "1.5rem",
                    marginRight: "8px",
                  }}
                >
                  ❌
                </span>{" "}
                {completeCountry.safetyData?.advisory.message}
              </Box>
            </Typography>
            <Typography variant="h6" gutterBottom>
              Color Guide For Warning Levels
            </Typography>
            {/* Extreme Warning Table */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#FF9999" }}>
                    <TableCell sx={{ fontWeight: "bold", color: "#660000" }}>
                      Extreme Warning (4.5 - 5)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      You should avoid any trips. A high warning index is a
                      sound indicator of potential harm to your health and
                      well-being.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* High Risk Table */}
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#FFDD99" }}>
                    <TableCell sx={{ fontWeight: "bold", color: "#665500" }}>
                      High Risk (3.5 - 4.5)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Travel should be reduced to a necessary minimum and be
                      conducted with good preparation and high attention.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* Medium Risk Table */}
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#99CCFF" }}>
                    <TableCell sx={{ fontWeight: "bold", color: "#003366" }}>
                      Medium Risk (2.5 - 3.5)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Warnings often relate to specific regions within a
                      country. However, high attention is still advised when
                      moving around.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* Low Risk Table */}
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#99FF99" }}>
                    <TableCell sx={{ fontWeight: "bold", color: "#006600" }}>
                      Low Risk (0 - 2.5)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Usually this is an indicator that travelling in this
                      country is relatively safe. Higher attention is advised
                      with values &gt; 1.
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {selectedTab === 4 && (
          <Box>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography variant="h6">Top Dating Apps</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                <TableBody>
                  {completeCountry?.datingAppsData?.data?.topDatingApps &&
                  completeCountry.datingAppsData.data.topDatingApps.length >
                    0 ? (
                    completeCountry.datingAppsData.data.topDatingApps.map(
                      (app, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <span
                              style={{ fontSize: "1.5rem", marginRight: "8px" }}
                            >
                              {index + 1}
                            </span>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              <strong>{app.appName}</strong> <br /> by{" "}
                              {app.publisher}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <Typography variant="body2">N/A</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        {selectedTab === 5 && (
          <Box>
            {/* Warning Tooltip */}
            <Box sx={{ textAlign: "center", padding: 1 }}>
              <Tooltip title="This information is aimed at US citizens.">
                <Typography variant="body2" color="textSecondary">
                  <strong>Note:</strong> Please be aware that the following
                  information is specifically intended for US citizens.
                </Typography>
              </Tooltip>
            </Box>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography variant="h6">Quick Facts</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Fact</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Details</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                <TableBody>
                  {completeCountry?.visaData?.data?.quickFacts &&
                  Object.entries(completeCountry.visaData.data.quickFacts)
                    .length > 0 ? (
                    Object.entries(
                      completeCountry.visaData.data.quickFacts
                    ).map(([key, value]) => {
                      // Normalize the key for emoji lookup
                      const normalizedKey = key
                        .toLowerCase() // Convert to lowercase
                        .replace(/:/g, "") // Remove colons
                        .replace(/_/g, " ") // Replace underscores with spaces
                        .trim(); // Remove extra spaces

                      return (
                        <TableRow key={key}>
                          <TableCell>
                            <span
                              style={{ fontSize: "1.5rem", marginRight: "8px" }}
                            >
                              {emojiVisaList[normalizedKey] || "❓"}
                            </span>
                            <strong>
                              {key
                                .replace(/_/g, " ") // Replace underscores with spaces
                                .replace(/:$/, "") // Remove trailing colon if present
                                .split(" ") // Split into words
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase() // Capitalize the first letter of each word
                                )
                                .join(" ")}{" "}
                              {/* Join the words back together */}
                            </strong>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">{value}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <Typography variant="body2">N/A</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Visa Info Section */}
            <Box
              sx={{
                marginTop: 4,
                padding: 2,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Visa Info
              </Typography>
              <Box>
                {splitIntoParagraphs(
                  replaceLinksInText(
                    completeCountry?.visaData?.data?.visaInfo ||
                      "No visa information available.",
                    completeCountry?.visaData?.data?.links || []
                  )
                ).map((paragraph, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    paragraph
                    dangerouslySetInnerHTML={{ __html: paragraph }} // Render HTML content if links are present
                  />
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
