import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import ChatIcon from "@mui/icons-material/Chat";

const CommunityPage = () => {
  const [isPaidUser, setIsPaidUser] = useState(false);

  useEffect(() => {
    // Check if the user has a paid subscription.
    const checkUserPaidStatus = () => {
      const paidStatus = localStorage.getItem("userPaidStatus") === "true";
      setIsPaidUser(paidStatus);
    };
    checkUserPaidStatus();
  }, []);

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {isPaidUser ? (
        <Card elevation={3}>
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ textAlign: "center" }}
            >
              <Typography variant="h4" component="h1" gutterBottom>
                Join Our Private Discord Community
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Connect instantly with other like-minded individuals in our safe and private community.
                Get real-time advice, support, and the chance to make lasting connections with fellow Passport Bros.
                <br />
                Whether you're seeking advice for your next adventure or just looking to chat, our Discord community
                is the perfect space to engage in valuable conversations without the noise of trolls or distractions.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Expect a welcoming environment where everyone can contribute, ask questions, and build connections.
                This is your space to share experiences, insights, and learn from others on a similar journey.
              </Typography>
              <Box sx={{ mt: 4 }}>
                <Link href="https://discord.gg/zWaFSfpj" target="_blank" rel="noopener noreferrer" underline="none">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ChatIcon />} // Use a Discord icon if available
                    size="large"
                  >
                    Join Our Discord Community
                  </Button>
                </Link>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <Typography variant="h6" align="center">
            This content is for members only. Please subscribe to access it.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default CommunityPage;
