import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Sitemark from "./SitemarkIcon";
import logo from "../assets/mypassportbros-logo.png";
import { Link } from "react-router-dom";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: 0, // Remove rounded corners
  border: "none", // Remove border
  backgroundColor: theme.palette.background.paper, // Solid background color
  boxShadow: theme.shadows[1],
  padding: "8px 36px",
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // Check if the user has a paid subscription
  const isPaidUser = localStorage.getItem('userPaidStatus') === 'true';

  return (
    <AppBar
      position="sticky" // Make the AppBar sticky at the top
      sx={{
        boxShadow: 0,
        bgcolor: "background.paper",
        backgroundImage: "none",
        width: "100vw", // Full width
        left: 0, // Align to the left
        right: 0, // Align to the right
      }}
    >
      <StyledToolbar variant="dense" disableGutters>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}>
          <Link to="/">
            <Sitemark isSvg={false} src={logo} alt="The mypassportbros logo." />
          </Link>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Button href="/" variant="text" size="small">
              Home
            </Button>
            <Button href="/about-us" variant="text" size="small">
              About Us
            </Button>
            <Button href="/faq" variant="text" size="small">
              FAQ
            </Button>
            <Button href="/contact-us" variant="text" size="small">
              Contact Us
            </Button>
            {isPaidUser && (
              <>
                <Button href="/mypassportbros-list" variant="text" size="small">
                  MyPassportBros List
                </Button>
                <Button href="/discord-community" variant="text" size="small">
                  Private Discord Community
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 1,
            alignItems: "center",
          }}
        >
          {/* Placeholder for Sign in/Sign up buttons */}
        </Box>
        <Box sx={{ display: { sm: "flex", md: "none" } }}>
          <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
            <Box sx={{ p: 2, backgroundColor: "background.default" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
              <Divider sx={{ my: 3 }} />
              <MenuItem component={Link} to="/">
                Home
              </MenuItem>
              <MenuItem component={Link} to="/about-us">
                About Us
              </MenuItem>
              <MenuItem component={Link} to="/faq">
                FAQ
              </MenuItem>
              <MenuItem component={Link} to="/contact-us">
                Contact Us
              </MenuItem>
              {isPaidUser && (
                <>
                  <MenuItem component={Link} to="/mypassportbros-list">
                    MyPassportBros List
                  </MenuItem>
                  <MenuItem component={Link} to="/discord-community">
                    Private Discord Community
                  </MenuItem>
                </>
              )}
            </Box>
          </Drawer>
        </Box>
      </StyledToolbar>
    </AppBar>
  );
}
