import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

// Define your custom primary color and font
const customTheme = createTheme({
  palette: {
    primary: {
      main: '#4169e1', // Your custom primary color
      contrastText: '#FFFFFF', // Text on primary color
    },
  },
  typography: {
    fontFamily: '"Inter", "sans-serif"', // Set font to Inter
  },
});

// Export a theme that merges custom settings with default MUI settings
const theme = deepmerge(createTheme(), customTheme);

export default theme;
