import React, { useState, useEffect } from "react";
import CountryList from "../components/CountryList";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LZString from "lz-string";
import Typography from "@mui/material/Typography";

function ListPage({ demoMode = false }) {
  const [completeCountryList, setCompleteCountryList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(process.env.REACT_APP_API_KEY);

  // Check if the user has a paid subscription.
  const isPaidUser = localStorage.getItem("userPaidStatus") === "true";

  useEffect(() => {
    // Using regex to normalize the country names.
    const normalizeCountryName = (name) => {
      if (!name) return "";
      return name
        .toLowerCase()
        .replace(/[\s\-_]+/g, " ")
        .trim();
    };
    // Prepares the list for proccessing.
    const preprocessList = (list, nameAccessor) => {
      return list.map((item) => ({
        normalizedName: normalizeCountryName(nameAccessor(item)),
        data: item,
      }));
    };

    const createCountryData = async () => {
      try {
        // Retrieve and decompress data.
        const cachedData = localStorage.getItem("completeCountryList");
        if (cachedData) {
          console.log("Using cached data");
          setCompleteCountryList(JSON.parse(LZString.decompress(cachedData)));
          setIsLoading(false);
          return;
        }

        // Fetch data.
        const countryList = await fetchCountryData();
        const demographicList = await fetchDemographicData();
        const safetyList = await fetchSafetyData();
        const costOfLivingList = await fetchCostOfLivingData();
        const datingAppsList = await fetchDatingAppsData();
        const visaList = await fetchVisaData();
        const completeCountryList = [];

        // Preprocess lists
        const normalizedCountryList = preprocessList(
          countryList,
          (country) => country.name?.common
        );

        const normalizedDemographicList = preprocessList(
          demographicList,
          (demo) =>
            demo?.Government?.["Country name"]?.["conventional short form"]
              ?.text
        );

        const normalizedSafetyList = preprocessList(
          Object.values(safetyList.data),
          (safety) => safety?.name
        );

        const normalizedCostOfLivingList = preprocessList(
          costOfLivingList,
          (costOfLiving) => costOfLiving?.countryName
        );

        const normalizedDatingAppsList = preprocessList(
          datingAppsList,
          (datingApps) => datingApps?.countryName
        );

        const normalizedVisaList = preprocessList(
          visaList,
          (visa) => visa?.countryName
        );

        normalizedCountryList.forEach((countryItem) => {
          const countryName = countryItem.normalizedName;

          // Find matching demographic data
          const demographicMatch = normalizedDemographicList.find(
            (demoItem) => demoItem.normalizedName === countryName
          );

          // Find matching safety data
          const safetyMatch = normalizedSafetyList.find(
            (safetyItem) => safetyItem.normalizedName === countryName
          );

          // Find matching cost of living data
          const costOfLivingMatch = normalizedCostOfLivingList.find(
            (costOfLivingItem) =>
              costOfLivingItem.normalizedName === countryName
          );

          // Find matching datings apps data
          const datingAppsMatch = normalizedDatingAppsList.find(
            (datingApps) => datingApps.normalizedName === countryName
          );
          // Assign datingAppsData based on whether a match is found.
          const datingAppsData = datingAppsMatch ? datingAppsMatch : null;

          // Find matching visa data.
          const visaMatch = normalizedVisaList.find(
            (datingApps) => datingApps.normalizedName === countryName
          );

          // Assign visaData based on whether a match is found.
          const visaData = visaMatch ? visaMatch : null;

          // Create a complete country object.
          if (demographicMatch && safetyMatch && costOfLivingMatch) {
            const completeCountryObject = {
              countryName: countryItem.data.name?.common,
              countryData: countryItem.data,
              demographicData: demographicMatch.data,
              safetyData: safetyMatch.data,
              costOfLivingData: costOfLivingMatch.data,
              datingAppsData: datingAppsData,
              visaData: visaData,
            };

            completeCountryList.push(completeCountryObject);
          } else {
            console.warn(`No match found for ${countryItem.data.name?.common}`);
          }
        });

        setCompleteCountryList(completeCountryList);
        // Compress and store fetched data in localStorage.
        const compressedData = LZString.compress(
          JSON.stringify(completeCountryList)
        );
        localStorage.setItem("completeCountryList", compressedData);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      } finally {
        setIsLoading(false); // Set loading to false once the data is fetched
      }
    };

    // Fetch country data when the component mounts.
    const fetchCountryData = async () => {
      try {
        const countryUrl = "https://restcountries.com/v3.1/all";
        const response = await fetch(countryUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    const fetchWithRetry = async (url, retries = 3, delay = 1000) => {
      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.statusText}`);
          }
          return await response.json();
        } catch (error) {
          console.error(
            `Attempt ${attempt} failed for ${url}: ${error.message}`
          );
          if (attempt === retries) {
            console.error(`Failed to fetch after ${retries} attempts: ${url}`);
            return null; // Return null if all retries fail
          }
          await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
        }
      }
    };

    // Fetch demographic data when the component mounts
    const fetchDemographicData = async () => {
      try {
        // The regions and countries two letter GEC codes in object form.
        const regionAndGecCodeList = [
          // Africa
          { code: "ag", region: "africa" },
          { code: "ao", region: "africa" },
          { code: "bc", region: "africa" },
          { code: "bn", region: "africa" },
          { code: "by", region: "africa" },
          { code: "cd", region: "africa" },
          { code: "cf", region: "africa" },
          { code: "cg", region: "africa" },
          { code: "cm", region: "africa" },
          { code: "cn", region: "africa" },
          { code: "ct", region: "africa" },
          { code: "cv", region: "africa" },
          { code: "dj", region: "africa" },
          { code: "eg", region: "africa" },
          { code: "ek", region: "africa" },
          { code: "er", region: "africa" },
          { code: "et", region: "africa" },
          { code: "ga", region: "africa" },
          { code: "gb", region: "africa" },
          { code: "gh", region: "africa" },
          { code: "gv", region: "africa" },
          { code: "iv", region: "africa" },
          { code: "ke", region: "africa" },
          { code: "li", region: "africa" },
          { code: "lt", region: "africa" },
          { code: "ly", region: "africa" },
          { code: "ma", region: "africa" },
          { code: "mi", region: "africa" },
          { code: "ml", region: "africa" },
          { code: "mo", region: "africa" },
          { code: "mp", region: "africa" },
          { code: "mr", region: "africa" },
          { code: "mz", region: "africa" },
          { code: "ng", region: "africa" },
          { code: "ni", region: "africa" },
          { code: "od", region: "africa" },
          { code: "pu", region: "africa" },
          { code: "rw", region: "africa" },
          { code: "se", region: "africa" },
          { code: "sf", region: "africa" },
          { code: "sg", region: "africa" },
          { code: "sh", region: "africa" },
          { code: "sl", region: "africa" },
          { code: "so", region: "africa" },
          { code: "su", region: "africa" },
          { code: "to", region: "africa" },
          { code: "tp", region: "africa" },
          { code: "ts", region: "africa" },
          { code: "tz", region: "africa" },
          { code: "ug", region: "africa" },
          { code: "uv", region: "africa" },
          { code: "wa", region: "africa" },
          { code: "wi", region: "africa" },
          { code: "wz", region: "africa" },
          { code: "za", region: "africa" },
          { code: "zi", region: "africa" },

          // Antarctica
          { code: "ay", region: "antarctica" },
          { code: "bv", region: "antarctica" },
          { code: "fs", region: "antarctica" },
          { code: "hm", region: "antarctica" },

          // Australia-Oceania
          { code: "aq", region: "australia-oceania" },
          { code: "as", region: "australia-oceania" },
          { code: "at", region: "australia-oceania" },
          { code: "bp", region: "australia-oceania" },
          { code: "ck", region: "australia-oceania" },
          { code: "cq", region: "australia-oceania" },
          { code: "cr", region: "australia-oceania" },
          { code: "cw", region: "australia-oceania" },
          { code: "fj", region: "australia-oceania" },
          { code: "fm", region: "australia-oceania" },
          { code: "fp", region: "australia-oceania" },
          { code: "gq", region: "australia-oceania" },
          { code: "kr", region: "australia-oceania" },
          { code: "kt", region: "australia-oceania" },
          { code: "nc", region: "australia-oceania" },
          { code: "ne", region: "australia-oceania" },
          { code: "nf", region: "australia-oceania" },
          { code: "nh", region: "australia-oceania" },
          { code: "nr", region: "australia-oceania" },
          { code: "nz", region: "australia-oceania" },
          { code: "pc", region: "australia-oceania" },
          { code: "ps", region: "australia-oceania" },
          { code: "rm", region: "australia-oceania" },
          { code: "tl", region: "australia-oceania" },
          { code: "tn", region: "australia-oceania" },
          { code: "tv", region: "australia-oceania" },
          { code: "um", region: "australia-oceania" },
          { code: "wf", region: "australia-oceania" },
          { code: "wq", region: "australia-oceania" },
          { code: "ws", region: "australia-oceania" },

          // Central America & Caribbean
          { code: "aa", region: "central-america-n-caribbean" },
          { code: "ac", region: "central-america-n-caribbean" },
          { code: "av", region: "central-america-n-caribbean" },
          { code: "bb", region: "central-america-n-caribbean" },
          { code: "bf", region: "central-america-n-caribbean" },
          { code: "bh", region: "central-america-n-caribbean" },
          { code: "bq", region: "central-america-n-caribbean" },
          { code: "cj", region: "central-america-n-caribbean" },
          { code: "cs", region: "central-america-n-caribbean" },
          { code: "cu", region: "central-america-n-caribbean" },
          { code: "do", region: "central-america-n-caribbean" },
          { code: "dr", region: "central-america-n-caribbean" },
          { code: "es", region: "central-america-n-caribbean" },
          { code: "gj", region: "central-america-n-caribbean" },
          { code: "gt", region: "central-america-n-caribbean" },
          { code: "ha", region: "central-america-n-caribbean" },
          { code: "ho", region: "central-america-n-caribbean" },
          { code: "jm", region: "central-america-n-caribbean" },
          { code: "mh", region: "central-america-n-caribbean" },
          { code: "nn", region: "central-america-n-caribbean" },
          { code: "nu", region: "central-america-n-caribbean" },
          { code: "pm", region: "central-america-n-caribbean" },
          { code: "rn", region: "central-america-n-caribbean" },
          { code: "rq", region: "central-america-n-caribbean" },
          { code: "sc", region: "central-america-n-caribbean" },
          { code: "st", region: "central-america-n-caribbean" },
          { code: "tb", region: "central-america-n-caribbean" },
          { code: "td", region: "central-america-n-caribbean" },
          { code: "tk", region: "central-america-n-caribbean" },
          { code: "uc", region: "central-america-n-caribbean" },
          { code: "vc", region: "central-america-n-caribbean" },
          { code: "vi", region: "central-america-n-caribbean" },
          { code: "vq", region: "central-america-n-caribbean" },

          // Central Asia
          { code: "kg", region: "central-asia" },
          { code: "kz", region: "central-asia" },
          { code: "rs", region: "central-asia" },
          { code: "ti", region: "central-asia" },
          { code: "tx", region: "central-asia" },
          { code: "uz", region: "central-asia" },

          // East & Southeast Asia
          { code: "bm", region: "east-n-southeast-asia" },
          { code: "bx", region: "east-n-southeast-asia" },
          { code: "cb", region: "east-n-southeast-asia" },
          { code: "ch", region: "east-n-southeast-asia" },
          { code: "hk", region: "east-n-southeast-asia" },
          { code: "id", region: "east-n-southeast-asia" },
          { code: "ja", region: "east-n-southeast-asia" },
          { code: "kn", region: "east-n-southeast-asia" },
          { code: "ks", region: "east-n-southeast-asia" },
          { code: "la", region: "east-n-southeast-asia" },
          { code: "mc", region: "east-n-southeast-asia" },
          { code: "mg", region: "east-n-southeast-asia" },
          { code: "my", region: "east-n-southeast-asia" },
          { code: "pf", region: "east-n-southeast-asia" },
          { code: "pg", region: "east-n-southeast-asia" },
          { code: "pp", region: "east-n-southeast-asia" },
          { code: "rp", region: "east-n-southeast-asia" },
          { code: "sn", region: "east-n-southeast-asia" },
          { code: "th", region: "east-n-southeast-asia" },
          { code: "tt", region: "east-n-southeast-asia" },
          { code: "tw", region: "east-n-southeast-asia" },
          { code: "vm", region: "east-n-southeast-asia" },

          // Europe
          { code: "al", region: "europe" },
          { code: "an", region: "europe" },
          { code: "au", region: "europe" },
          { code: "ax", region: "europe" },
          { code: "be", region: "europe" },
          { code: "bk", region: "europe" },
          { code: "bo", region: "europe" },
          { code: "bu", region: "europe" },
          { code: "cy", region: "europe" },
          { code: "da", region: "europe" },
          { code: "dx", region: "europe" },
          { code: "ee", region: "europe" },
          { code: "ei", region: "europe" },
          { code: "en", region: "europe" },
          { code: "ez", region: "europe" },
          { code: "fi", region: "europe" },
          { code: "fo", region: "europe" },
          { code: "fr", region: "europe" },
          { code: "gi", region: "europe" },
          { code: "gk", region: "europe" },
          { code: "gm", region: "europe" },
          { code: "gr", region: "europe" },
          { code: "hr", region: "europe" },
          { code: "hu", region: "europe" },
          { code: "ic", region: "europe" },
          { code: "im", region: "europe" },
          { code: "it", region: "europe" },
          { code: "je", region: "europe" },
          { code: "jn", region: "europe" },
          { code: "kv", region: "europe" },
          { code: "lg", region: "europe" },
          { code: "lh", region: "europe" },
          { code: "lo", region: "europe" },
          { code: "ls", region: "europe" },
          { code: "lu", region: "europe" },
          { code: "md", region: "europe" },
          { code: "mj", region: "europe" },
          { code: "mk", region: "europe" },
          { code: "mn", region: "europe" },
          { code: "mt", region: "europe" },
          { code: "nl", region: "europe" },
          { code: "no", region: "europe" },
          { code: "pl", region: "europe" },
          { code: "po", region: "europe" },
          { code: "ri", region: "europe" },
          { code: "ro", region: "europe" },
          { code: "si", region: "europe" },
          { code: "sm", region: "europe" },
          { code: "sp", region: "europe" },
          { code: "sv", region: "europe" },
          { code: "sw", region: "europe" },
          { code: "sz", region: "europe" },
          { code: "uk", region: "europe" },
          { code: "up", region: "europe" },
          { code: "vt", region: "europe" },

          // Middle East
          { code: "ae", region: "middle-east" },
          { code: "aj", region: "middle-east" },
          { code: "am", region: "middle-east" },
          { code: "ba", region: "middle-east" },
          { code: "gg", region: "middle-east" },
          { code: "gz", region: "middle-east" },
          { code: "ir", region: "middle-east" },
          { code: "is", region: "middle-east" },
          { code: "iz", region: "middle-east" },
          { code: "jo", region: "middle-east" },
          { code: "ku", region: "middle-east" },
          { code: "le", region: "middle-east" },
          { code: "mu", region: "middle-east" },
          { code: "qa", region: "middle-east" },
          { code: "sa", region: "middle-east" },
          { code: "sy", region: "middle-east" },
          { code: "tu", region: "middle-east" },
          { code: "we", region: "middle-east" },
          { code: "ym", region: "middle-east" },

          // North America
          { code: "bd", region: "north-america" },
          { code: "ca", region: "north-america" },
          { code: "gl", region: "north-america" },
          { code: "ip", region: "north-america" },
          { code: "mx", region: "north-america" },
          { code: "sb", region: "north-america" },
          { code: "us", region: "north-america" },

          // Oceans
          // (If needed, you can list oceans here with their codes)

          // South America
          { code: "ar", region: "south-america" },
          { code: "bl", region: "south-america" },
          { code: "br", region: "south-america" },
          { code: "ci", region: "south-america" },
          { code: "co", region: "south-america" },
          { code: "ec", region: "south-america" },
          { code: "fk", region: "south-america" },
          { code: "gy", region: "south-america" },
          { code: "ns", region: "south-america" },
          { code: "pa", region: "south-america" },
          { code: "pe", region: "south-america" },
          { code: "sx", region: "south-america" },
          { code: "uy", region: "south-america" },
          { code: "ve", region: "south-america" },

          // South Asia
          { code: "af", region: "south-asia" },
          { code: "bg", region: "south-asia" },
          { code: "bt", region: "south-asia" },
          { code: "ce", region: "south-asia" },
          { code: "in", region: "south-asia" },
          { code: "io", region: "south-asia" },
          { code: "mv", region: "south-asia" },
          { code: "np", region: "south-asia" },
          { code: "pk", region: "south-asia" },
        ];

        // Loop through the region and GEC code array and fetch all the country data.
        const fetchPromises = regionAndGecCodeList.map(async (element) => {
          const demographicUrl = `https://raw.githubusercontent.com/factbook/factbook.json/master/${element.region}/${element.code}.json`;
          return await fetchWithRetry(demographicUrl);
        });

        const demographicList = await Promise.all(fetchPromises);

        return demographicList.filter((data) => data !== null);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    // Fetch safety data when the component mounts.
    const fetchSafetyData = async () => {
      try {
        const safetyUrl = "https://www.travel-advisory.info/api";
        const response = await fetch(safetyUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    // Fetch cost of living data when the component mounts.
    const fetchCostOfLivingData = async () => {
      try {
        const costOfLivingUrl =
          "https://api.github.com/repos/ale356/col-web-scraper/contents/src/data/cost-of-living-data.json";
        const response = await fetch(costOfLivingUrl, {
          headers: {
            Authorization: `token ${accessToken}`,
            Accept: "application/vnd.github.v3.raw", // Ensures you get the raw file content
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    // Fetch dating apps data when the component mounts.
    const fetchDatingAppsData = async () => {
      try {
        const datingAppsUrl =
          "https://api.github.com/repos/ale356/dating-apps-web-scraper/contents/src/data/top-dating-apps.json";
        const response = await fetch(datingAppsUrl, {
          headers: {
            Authorization: `token ${accessToken}`,
            Accept: "application/vnd.github.v3.raw", // Ensures you get the raw file content
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    // Fetch visa data when the component mounts.
    const fetchVisaData = async () => {
      try {
        const visaUrl =
          "https://api.github.com/repos/ale356/visa-web-scraper/contents/src/data/visa-information-data.json";
        const response = await fetch(visaUrl, {
          headers: {
            Authorization: `token ${accessToken}`,
            Accept: "application/vnd.github.v3.raw", // Ensures you get the raw file content
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    createCountryData();
  }, []); // Empty dependency array means this runs once when the component mounts.

  // Filter the list for demo mode.
  const displayedCountryList = demoMode
    ? completeCountryList?.slice(15, 19)
    : completeCountryList;

  return (
    <div>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" align="center" gutterBottom>
          {demoMode
            ? "MyPassPortBros Country List Demo"
            : "MyPassPortBros Country List"}
        </Typography>
      </Box>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress /> {/* Show spinner while loading */}
        </Box>
      ) : isPaidUser || demoMode ? (
        displayedCountryList && displayedCountryList.length > 0 ? (
          <CountryList completeCountryList={displayedCountryList} />
        ) : (
          <Typography variant="h6" align="center">
            No countries available.
          </Typography>
        )
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <Typography variant="h6" align="center">
            This content is for members only. Please subscribe to access it.
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default ListPage;
