import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to <strong>MyPassPortBros</strong>. By accessing or using our
          platform, you agree to comply with and be bound by the following Terms
          and Conditions. Please review these terms carefully before using our
          services.
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* Section 1: Definitions */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            1. Definitions
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Service:</strong> Refers to all the services provided by
            MyPassPortBros, including but not limited to access to travel
            insights, dating advice, cost of living data, safety guidelines, and
            the private community.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Member:</strong> Any individual who has signed up for and
            paid for access to the MyPassPortBros services.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>We/Us/Company:</strong> Refers to MyPassPortBros.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 2: Acceptance of Terms */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            2. Acceptance of Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By accessing, browsing, or using any part of the MyPassPortBros
            website or services, you accept and agree to be bound by these Terms
            and Conditions. If you do not agree with any part of these terms,
            you must stop using the service immediately.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 3: Eligibility */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            3. Eligibility
          </Typography>
          <Typography variant="body1" paragraph>
            You must be at least 18 years old to use our services. By
            registering for an account, you confirm that you are 18 or older and
            legally capable of entering into a binding agreement.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 4: Membership and Subscription */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            4. Membership and Subscription
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Membership Access:</strong> Access to our services is
            provided through a subscription membership.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>No Refund Policy:</strong> Once a payment is made for a
            subscription, it is non-refundable. No refunds will be provided for
            partial or unused memberships.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Cancellation:</strong> You may cancel your membership at any
            time by contacting us through the platform or your account settings.
            Upon cancellation, you will still retain access to the service until
            the end of your current billing cycle.
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Automatic Renewal:</strong> Your membership will
            automatically renew at the end of the subscription term unless
            canceled prior to the renewal date.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 5: Payment Terms */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            5. Payment Terms
          </Typography>
          <Typography variant="body1" paragraph>
            By subscribing to MyPassPortBros, you agree to provide valid and
            updated payment information. All subscription fees are due upfront,
            either on a monthly or yearly basis depending on the plan you
            select. Failure to provide accurate payment information may result
            in the suspension or termination of your account.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 6: License and Use of Services */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            6. License and Use of Services
          </Typography>
          <Typography variant="body1" paragraph>
            You are granted a limited, non-exclusive, non-transferable license
            to access and use MyPassPortBros services solely for personal,
            non-commercial purposes.
          </Typography>
          <Typography variant="body1" paragraph>
            You agree not to: Copy, distribute, or modify any part of the
            service without explicit permission. Reverse-engineer or attempt to
            gain unauthorized access to the platform. Share your account details
            with anyone else. Use the service for any unlawful or harmful
            activities.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Sections 7-16 */}
        {/* Repeat similar sections here following the pattern, each wrapped with Box and Typography for titles and paragraphs */}

        {/* Section 7: Intellectual Property */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            7. Intellectual Property
          </Typography>
          <Typography variant="body1" paragraph>
            All content, software, and services on the MyPassPortBros platform
            are the exclusive property of the company or its licensors. You
            agree not to reproduce, duplicate, copy, sell, or exploit any
            portion of the service without our express written consent.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 8: Limitation of Liability */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            8. Limitation of Liability
          </Typography>
          <Typography variant="body1" paragraph>
            To the fullest extent permitted by law, MyPassPortBros and its
            affiliates will not be liable for any direct, indirect, incidental,
            consequential, or punitive damages arising out of your use or
            inability to use the service. This includes but is not limited to
            loss of profits, data, or goodwill, even if we have been advised of
            the possibility of such damages.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 9: Dispute Resolution */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            9. Dispute Resolution
          </Typography>
          <Typography variant="body1" paragraph>
            Any disputes arising under or in connection with these Terms and
            Conditions will be subject to the exclusive jurisdiction of the
            courts in Sweden.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 10: Modifications to Service and Pricing */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            10. Modifications to Service and Pricing
          </Typography>
          <Typography variant="body1" paragraph>
            MyPassPortBros reserves the right to modify, suspend, or discontinue
            the service (or any part thereof) at any time, with or without
            notice. Pricing for our services may change upon notice to you, but
            any price changes will only affect future billing cycles.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 11: Termination */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            11. Termination
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to terminate your account and access to the
            service at our discretion, for any reason, including but not limited
            to a breach of these terms.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 12: Indemnification */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            12. Indemnification
          </Typography>
          <Typography variant="body1" paragraph>
            You agree to indemnify and hold MyPassPortBros, its affiliates,
            officers, directors, employees, and agents harmless from any claims,
            liabilities, damages, losses, or expenses (including legal fees)
            arising out of your use of the service, your violation of these
            terms, or your infringement of any third-party rights.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 13: Privacy Policy */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            13. Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            Your use of the MyPassPortBros platform is also governed by our
            Privacy Policy, which can be found [here].
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 14: Governing Law */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            14. Governing Law
          </Typography>
          <Typography variant="body1" paragraph>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of Sweden, without regard to
            conflict of law principles.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 15: Changes to These Terms */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            15. Changes to These Terms
          </Typography>
          <Typography variant="body1" paragraph>
            We reserve the right to modify or update these Terms and Conditions
            at any time. Any changes will be posted on this page, and your
            continued use of the service after such changes have been made
            constitutes acceptance of the updated terms.
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Section 16: Contact Us */}
        <Box my={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            16. Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about these Terms and Conditions, feel
            free to reach out to us at:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Email</strong>: support@mypassportbros.com
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsAndConditions;
