import React, { Suspense } from "react";
import Divider from "@mui/material/Divider";
import Hero from "../components/Hero"; // Load Hero upfront

// Lazy loading other components
const Highlights = React.lazy(() => import("../components/Highlights"));
const Features = React.lazy(() => import("../components/Features"));
const Pricing = React.lazy(() => import("../components/Pricing"));
const Testimonials = React.lazy(() => import("../components/Testimonials"));
const FAQMarketing = React.lazy(() => import("../components/FAQMarketing"));

function MarketingPage() {
  return (
    <>
      <Hero /> {/* Load Hero component immediately */}

      <Suspense fallback={<div>Loading...</div>}>
        <div>
          <Highlights />
          <Features />
          <Divider />
          
          <Pricing />
          <Divider />
          
          <Testimonials />
          <Divider />
          
          <FAQMarketing />
          <Divider />
        </div>
      </Suspense>
    </>
  );
}

export default MarketingPage;
