import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import 'typeface-inter';

/**
 * Renders the root of the application, providing theme and color context.
 *
 * @component
 * @returns {JSX.Element} Root component of the application.
 */
function Root() {
  return (
    <React.StrictMode>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);
