import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from "@mui/material/styles";
import { Link } from "@mui/material";

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "100%",
  height: 400,
  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundColor: "black", // Set a background color for the box
  position: "relative", // Position for absolute positioning of the iframe
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
    height: 700,
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        }),
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 8.5, sm: 11.2 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" }, mb: 4 }} // Added margin bottom here
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              fontSize: {
                xs: "2.21rem",
                sm: "clamp(3rem, 10vw, 3.5rem)",
              },
            }}
          >
            Unlock the World with MyPassPortBros
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              Your All-in-One
            </Typography>
            Platform for Global Dating and Living!
          </Typography>

          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { sm: "100%", md: "80%" },
            }}
          >
            Explore new cultures, find love abroad, and live confidently with
            the ONLY platform tailored for Passport Bros. <b>Join now and start
            your adventure beyond borders today!</b>
          </Typography>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large" // Make the button larger
            sx={{ minWidth: "fit-content" }}
            href="https://buy.stripe.com/bIY8xk2y30JPaOI8wy"
            endIcon={<ArrowForwardIcon style={{ color: 'white' }} />}
          >
            Subscribe now
          </Button>

          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            By clicking "SUBSCRIBE NOW" you agree to our <Link href="/terms-and-conditions">Terms & Conditions</Link>.
          </Typography>
        </Stack>
        <StyledBox id="video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/-iz9r0J4K24"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </StyledBox>
      </Container>
    </Box>
  );
}
